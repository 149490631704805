import styled from 'styled-components';
import CampusImage from '../../assets/dmc-campus.jpg';

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

export const AlertsContainer = styled.div`
  .k-tabstrip-items .k-item {
    color: #0099cc;
  }
  .k-tabstrip {
    margin-top: 10px;
    > .k-content {
      padding: 0;
      border: 0;
    }
  }
`;

export const BadgeEditorContainer = styled.div`
  .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }
  .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
  }
  .ql-editor {
    min-height: 400px;
    min-width: 300px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  height: calc(100vh - 135px);
`;

export const ReportContainer = styled(Container)`
  min-height: calc(100vh - 135px);
  height: 100%;
`;

export const BuyListDataContainer = styled.div`
  max-width: calc(100vw - 430px);
  overflow: hidden;
  position: relative;
`;

export const Navigation = styled.div`
  overflow-x: hidden;
  a {
    display: block;
    padding: 5px;
    width: 200px;
    text-decoration: none;
    color: black;
  }
  a:hover {
    background-color: #f1f1f1;
  }
  .k-panelbar {
    width: 200px;
  }

  .k-panelbar:focus {
    border: rgba(0, 0, 0, 0.08);
  }

  .k-panelbar > .k-panelbar-header > .k-link :not(.k-state-selected) {
    color: black;
  }
  .k-child-animation-container {
    background: aliceblue;
  }
`;

export const Content = styled.div`
  width: calc(100vw - 359px);
  margin: 0 16px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
`;

export const BuyListButtonGroup = styled.div`
  position: absolute;
  right: 0;
  top: 16px;
`;

export const ContentGroup = styled.div`
  padding: 10px 0;

  div {
    font-weight: bold;
  }
`;

export const ProfileContainer = styled.div`
  padding: 40px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 400px 400px;
`;

export const Background = styled.div`
  background: linear-gradient(
    135deg,
    rgb(228, 245, 252) 18%,
    rgb(191, 232, 249) 55%,
    rgb(159, 216, 239) 1%,
    rgb(42, 176, 237) 88%
  );
  height: 100px;
`;

export const RelatedContainer = styled.div`
  display: flex;
  alignitems: flex-start;
  flex-flow: row wrap;
  width: 100%;
`;

export const ErrorContainer = styled.div`
  background: white;
  padding: 16px;
  margin: 40px auto;
  width: 500px;
  border-radius: 4px;

  h1 {
    font-size: 2em;
    margin-bottom: 14px;
  }

  button,
  a {
    color: #0099cc;
    cursor: pointer;
    transition: all 500ms;
    font-weight: bold;

    &:hover {
      color: #002946;
    }
  }
`;

export const AlertFormContainer = styled.div`
  form > button {
    display: block;
    margin: auto 0 auto auto;
  }
  .k-tabstrip > .k-content {
    padding: 0 !important;
  }
`;

export const SentListContainer = styled.div`
  padding-bottom: 20px;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
  div > .k-dropdown {
    width: 350px;
  }
`;

export const List = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

export const AssociateContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  display: flex;
  flex-direction: row !important;
  margin-bottom: 14px;
  align-items: center;

  a {
    color: #454e68;
  }
  & + a {
    font-weight: bold;
  }
`;

export const SendContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
  }
`;

export const CreateButtonContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const BuyListSubContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 18px;
  position: relative;
`;

export const ListMenuContainer = styled.div`
  width: 300px;
  border-right: 1px solid #ccc;
  margin-right: 8px;
  padding: 0 10px;

  h2 {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.5em;
  }
`;

export const ListDetailsContainer = styled.div`
  width: calc(100vw - 445px);
  margin-left: 8px;

  h2 {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1.5em;
  }
`;

export const FiltersContainer = styled.div`
  border: 1px solid #eee;
  padding: 16px;
  margin: 40px 0;
  h3 {
    font-size: 1.25em;
  }
`;

export const FieldGroup = styled.div`
  margin: 20px 0;
  width: 400px;

  .k-textbox,
  .k-textbox-container,
  .k-multiselect,
  .k-combobox {
    width: 100%;
  }

  .k-checkbox-label {
    display: inline-block;
    vertical-align: bottom;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
`;

export const Contact = styled.div`
  p {
    font-size: 10px;
  }
`;

export const ExportPDFContainer = styled.div`
  position: absolute;
  z-index: -1;
  p {
    font-size: 12px;
  }
`;

export const SalesContainer = styled.div`
  margin: 20px 0;
  li {
    font-size: 12px;
  }
  td {
    font-size: 10px;
    width: 200px;
  }
`;

export const AttachmentContainer = styled.div`
  width: 100%;
  ul {
    list-style: none;
    background: #fafafa;
    border-radius: 6px;
    padding: 10px;
    border: 1px solid #eee;
  }
  .filepond--list.filepond--list {
    top: -3px;
  }
`;

export const IconContainer = styled.div`
  position: relative;
`;

export const SupplementContainer = styled.div`
  position: absolute;
  border: #50495a;
  bottom: -7px;
  right: -5px;
`;

export const CompanyCell = styled.div`
  text-transform: capitalize;
  li {
    display: flex;
    justify-content: flex-start;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  .k-menu {
    border: 1px solid #596587;
    color: white;
    border-radius: 4px;
    .k-link {
      color: #596587;
    }
  }
  i.material-icons {
    font-size: 24px;
    background: #fafafa;
    border-radius: 4px;
    box-shadow: 0 1px 1px #ccc;
    :nth-of-type(2) {
      margin-left: 4px;
    }
  }
`;

export const BadgeHtmlContainer = styled.div`
  margin: 0 0 40px;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid;
  position: relative;
  max-width: 100%;
  min-height: 100px;

  .coupon {
    display: none;
  }
`;

export const RegistrationFormGroup = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 0;
  width: 45%;
  text-align: left;
  padding-bottom: 24px;
  height: 100%;

  .k-header,
  .k-textbox-container {
    width: 100%;
  }
`;

export const CouponContainer = styled.div`
  width: 100%;
  padding: 10px 0 0;
`;

export const CouponList = styled.div`
  overflow: hidden;
  border-radius: 4px;
  position: relative;
`;

export const CouponListItem = styled.div`
  background-color: #fff;
  line-height: 1.5rem;
  padding: 3px 7px;
  margin: 0 0 10px;
  border: 1px solid #e0e0e0;
  .coupon-html {
    display: none;
  }
  p {
    font-size: 12px;
    line-height: 1;
  }
  h5 {
    font-weight: bold;
  }
  span {
    font-size: 12px;
    font-style: italic;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const RegistrationContainer = styled.div`
  display: flex;
  width: 60vw;
  min-width: 600px;
  max-width: 900px;
  justify-content: space-between;
`;

export const GuestRegistrationContainer = styled.div`
  width: 60vw;
  min-width: 600px;
  max-width: 900px;

  input#isDeferred:not(:checked) {
    border: 2px solid black;
  }
`;

export const BadgePreviewContainer = styled.div`
  margin-left: 18px;
  position: relative;
  width: 50%;
`;

export const PolicyContainer = styled.div`
  margin: 15px 0;
  width: 100%;
`;

export const RegDataContainer = styled.div`
  margin-left: 32px;
  display: inline-block;
  p {
    text-transform: capitalize;
    margin-bottom: 8px;
    a {
      color: #0099cc;
    }
  }
`;

export const ActionableButtons = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const RegContainer = styled.div`
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin-top: 8px;
  transition: 1s all;
`;

export const EmailContainer = styled.div`
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export const MailContainer = styled.div`
  background-color: #f9fb93;
  margin-bottom: 10px;
  padding: 10px;
  1 p {
    color: #f9fb93;
  }
`;

export const MailCC = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  button {
    color: #0099cc;
    margin: 0px 2px;
  }
`;

export const TypeContainer = styled.div`
  label {
    margin: 8px 0;
    padding-left: 32px;
  }
`;

export const ImportEmailContainer = styled.div`
  margin-right: 5px;
  width: 50vw;
  min-height: 50vh;
  max-height: calc(100vh - 200px);
`;

export const ContainerSearch = styled.div`
  margin: 24px 0;
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 20px;
`;

export const CriticalAlertContainer = styled.div`
  margin-top: 16px;
`;

export const Item = styled.div`
  background: #bb1d00;
  text-align: center;
  border-radius: 4px;
  padding: 4px;
  margin: 4px 0;
  font-size: 12px;
  color: white;
`;

export const AlertText = styled.div`
  margin-bottom: 16px;
  position: relative;
  padding-top: 10px;
`;

export const AlertArea = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 16px;
`;

export const NavBarRightContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;

  .header-recents,
  .header-notifications {
    margin-right: 10px;
  }

  svg {
    color: #ccc;
    &:hover {
      color: #fafafa;
    }
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MergeGroupContainer = styled.div`
  margin-bottom: 12px;
`;

export const MergeButtonContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
  padding-top: 20px;
  height: 20px;
`;

export const MergeRecordContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  input {
    margin-right: 8px;
  }
`;

export const MergeCompareContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const MergeCompareFieldGroup = styled.div`
  input {
    margin-right: 8px;
  }

  &:last-of-type {
    margin-left: 18px;
  }
`;

export const AsscPanel = styled.div`
  padding: 20px;
  background: white;
  box-shadow: 0 1px 5px 0 rgba(45, 62, 80, 0.12);
  margin-bottom: 15px;

  h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

export const InfoSection = styled.div`
  margin: 0 0 16px;
  h5 {
    font-size: 12px;
    color: #0099cc;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const EmailAddress = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const typeCardStyling = (dataFromProps) => {
  const { selected, type } = dataFromProps;

  if (!selected) {
    return `#ccc`;
  }
  if (type === 'Buyer') {
    return `rgba(0,174,111,0.5)`;
  }
  if (type === 'Exhibitor') {
    return `rgba(255,181,72,0.5)`;
  }
  return 'initial';
};

export const CompanyCard = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => typeCardStyling(props)};
  border-radius: 4px;
  transition: all 500ms;
  margin: 5px 0;
  padding: 8px;
  width: 100%;

  &:hover {
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
`;

export const LeftColumn = styled.div`
  grid-area: left;
  margin: 5px;
`;

export const RightColumn = styled.div`
  grid-area: right;
  grid-column-start: 2;
  padding: 0 0 20px;
  min-height: 90vh;
  max-width: calc(100vw - 431px);
`;

export const DetailsControl = styled.div`
  grid-area: top;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  .k-menu {
    color: white;
    border-radius: 4px;
    background: #0099cc;

    .k-link {
      color: white;
    }

    .k-menu-link {
      color: white;
      padding: 6px 12px;

      &.k-state-active {
        color: white;
      }
    }

    .k-state-active {
      color: #0099cc;
    }
  }

  button {
    margin-right: 8px;
  }
`;

export const DetailsPanel = styled.div`
  grid-area: person;
  height: auto;
  border-radius: 4px;
  position: relative;
  align-self: start;
  margin-top: 19px;
  padding: 16px;
  background: white;
`;

export const MainCardCompany = styled(DetailsPanel)`
  margin-top: 0;
`;

export const MainCardPerson = styled(DetailsPanel)`
  margin: 0 5px 0 5px;
  h1 {
    text-align: center;
  }
`;

export const DetailSubContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #eee;
  margin: 0 0 12px;
  padding-bottom: 8px;
  flex-direction: column;

  &:last-of-type {
    border: 0;
    padding-bottom: 0;
  }

  p {
    text-transform: capitalize;
    padding-bottom: 8px;
    &.email {
      text-transform: lowercase;
      a {
        color: #084e8a;
        &:hover {
          color: #0099cc;
        }
      }
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

export const AssociatedBuyers = styled.div`
  grid-column-start: 1;
  grid-row: 5 / 6;
  height: auto;
  margin-top: 12px;
  background: white;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 40px;
  position: relative;

  h3 {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  p {
    text-transform: capitalize;
  }
`;

export const DetailsContainer = styled.div`
  height: 100%;
  flex-direction: column;
  display: grid;
  grid-template-areas:
    'top top'
    'left right';
  grid-template-columns: 350px auto;
  grid-gap: 2px;
  margin-left: 4px;
`;

export const DetailAlertContainer = styled.div`
  grid-area: top;
  margin-left: 40px;
`;

export const CompanyImageContainer = styled.div`
  margin-bottom: 12px;
  i {
    background: #eee;
    padding: 10px;
    border-radius: 100%;
  }
`;

export const DetailFormGroup = styled.div`
  margin-bottom: 8px;
`;

export const DetailPanelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #eee;
  margin: 4px auto 12px;
  flex-direction: column;
  padding: 0 0 10px 0;
  position: relative;

  &:last-of-type {
    border: 0;
  }

  p {
    padding-bottom: 8px;
    &.email {
      text-transform: lowercase;
      a {
        color: #084e8a;
        &:hover {
          color: #0099cc;
        }
      }
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  a[disabled] {
    color: inherit;
    pointer-events: none;
  }
`;

export const LayoutContainer = styled.div`
  h5 {
    font-weight: bold;
    margin-bottom: 15px;
  }
  width: 300px;
`;

export const SwitchGroup = styled.div`
  margin: 10px 0 15px;
`;

export const PersonIdContainer = styled.div`
  display: flex;
  justify-content: center;
  span {
    text-transform: lowercase;
    font-size: 14px;
  }
`;

export const CoAssociatedContainer = styled.div`
  margin: 10px 5px 5px;
  background: white;
  padding: 16px;
  border-radius: 4px;

  h2 {
    font-size: 1.15em;
    font-weight: bold;
  }
`;

export const AboutContainer = styled.div`
  margin: 10px 5px 5px;
  background: white;
  border-radius: 4px;
`;

export const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SelectPersonContainer = styled.div`
  padding: 12px 12px;
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #eee;
  margin-bottom: 18px;
  margin-top: 18px;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .k-switch {
    margin-left: 45px;
    &.k-switch-on {
      .k-switch-container {
        background-color: #0099cc;
      }
    }
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const TabContainerStyle = styled.div`
  height: 400px;
  padding: 34px 16px;
  position: relative;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;

  .button--add {
    top: 0;
  }
  .filter-button {
    top: -14px !important;
  }
`;

export const DataSection = styled.div`
  border: 0;
  border-radius: 4px;
  margin-left: 4px;
  grid-column-end: right;
  overflow-x: hidden;
  margin-top: 40px;
  z-index: 1;

  h3 {
    font-size: 1.2em;
  }
  .k-link {
    font-size: 16px;
  }
  .k-item {
    color: #002946;

    &:hover {
      color: purple;
    }
  }
  .k-tabstrip > .k-content {
    border-color: transparent;
  }
  .k-tabstrip .k-item.k-state-active {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .k-tabstrip-top > .k-tabstrip-items {
    border-bottom-width: 0;
    width: 90%;
    overflow: hidden;
  }
  .k-animation-container {
    width: 100%;
    max-height: -webkit-fill-available;
    overflow: auto;
  }
`;

export const ListItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
`;

export const MessageContainer = styled(ListItemContainer)`
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  label {
    width: 90%;
    padding-left: 15px;
  }
`;

export const LeadsActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 16px;
  position: sticky;
  bottom: 0;
  background: #eee;
  margin: 0 -12px -12px;
  z-index: 101;
`;

export const LeadsContainer = styled.div`
  width: 600px;
  background: #fafafa;
  padding: 12px;

  h5 {
    margin: 20px 0;
  }
`;

export const GroupHeading = styled.div`
  margin-bottom: 24px;

  h2 {
    font-size: 26px;
  }
`;

export const ModuleFormGroup = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  .k-header,
  .k-textbox-container {
    width: 100%;
  }
`;

export const GroupResults = styled.div`
  overflow: auto;
  height: calc(100vh - 255px);
`;

export const GroupsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 16px;
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
`;

export const ToolbarContainer = styled.div`
  display: flex;
  padding: 10px 0 10px 0;
  align-items: flex-start;
  button {
    margin-left: 5px;
  }
`;

export const SavedFilterContainer = styled.div`
  margin-left: auto;
`;

export const SavedFilterDropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover div {
    display: block;
  }

  div > div:hover {
    background-color: #f1f1f1;
  }
`;

export const SavedFilterDropdownMenu = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;

  button {
    outline: none;
  }
`;

export const SavedFilterFormGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;
  label {
    width: 80px;
  }
`;

export const CheckboxGroup = styled.div`
  column-count: auto;
  column-width: 300px;

  > div {
    margin: 5px 0 5px 0;
    > label {
      vertical-align: bottom;
    }
  }
`;

export const InactiveReasonContainer = styled.div`
  background-color: #bb1d00;
  color: white;
  padding: 5px 15px 15px;
  border-radius: 4px;
  textarea {
    background-color: #ffffff;
    resize: none;
    width: 100%;
  }
  .k-state-empty > .k-label {
    color: #656565;
  }
`;

export const WindowBody = styled.div`
  padding: 0 16px;
  flex: 1 0 auto;
`;

export const CommunicationHeader = styled.div`
  margin: 10px 5px;
`;
export const CommunicationBody = styled.div`
  padding: 0 5px;
`;
export const CommunicationMessage = styled.div`
  border-bottom: 3px solid #ccc;
  padding-bottom: 10px;
`;

export const HtmlContent = styled.div`
  b,
  strong {
    font-weight: bold;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
`;

export const KioskContainer = styled.div`
  text-align: center;
  color: #424456;
  .k-numerictextbox .k-numeric-wrap .k-input,
  .k-textbox {
    height: 60px;
    padding: 10px;
    flex: 1;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2),
      0 2px 2px -1px rgba(0, 0, 0, 0.025);
    transition: box-shadow 120ms ease;
    font-weight: 700;
    width: 100%;
  }
  .k-textbox-container {
    width: 300px;
    margin: 5px;
  }

  height: 100vh;
  /*background-image: linear-gradient(
      to bottom,
      rgb(247 247 249 / 52%),
      rgb(193 193 193)
    ),
    url(${CampusImage});*/
  background-size: cover;
`;

export const KioskBody = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  text-align: center;
  z-index: 1000;
  border-color: #f8f9fa;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 10px 14px rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 10%);
  min-width: calc(100vw - 500px);
  min-height: calc(100vh - 500px);
`;

export const KioskPaymentContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

  input.k-textbox {
    height: 40px !important;
  }
`;

export const KioskGuestContainer = styled.div`
  .k-textbox-container {
    width: 100%;
    height: 64px;
    margin: 5px;
  }

  input {
    font-size: xx-large !important;
  }

  button {
    border-radius: 0;
  }

  margin: 20px;
`;

export const KioskArrowAnimationContainer = styled.div`
  position: absolute;
  width: 600px;
  top: 20px;
  right: 0;
  z-index: 10000;
  border-color: #ffffff;
  border-radius: 1rem 0 0 1rem;
  background: rgb(0 0 0 / 85%);
  box-shadow: 0 10px 14px rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 10%);
  color: white;
  height: 120px;

  #arrowAnim {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow {
    width: 20px;
    height: 20px;
    border: 5px solid;
    border-color: white transparent transparent white;
    transform: rotate(-225deg);
  }

  .arrowSliding {
    position: absolute;
    -webkit-animation: slide 5s linear infinite;
    animation: slide 5s linear infinite;
    left: 0;
  }

  .delay1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  .delay2 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
  .delay3 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }

  .delay4 {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }

  .arrow.red {
    border-color: #f32735 transparent transparent #f32735;
  }

  .arrow.blue {
    border-color: #00a7e1 transparent transparent #00a7e1;
  }

  .arrow.green {
    border-color: #00ac6c transparent transparent #00ac6c;
  }

  .arrow.yellow {
    border-color: #ffb548 transparent transparent #ffb548;
  }

  .arrow.pink {
    border-color: #f27fb2 transparent transparent #f27fb2;
  }

  @-webkit-keyframes slide {
    0% {
      opacity: 0;
      transform: translateX(0);
    }
    33% {
      opacity: 1;
      transform: translateX(200px);
    }
    66% {
      opacity: 1;
      transform: translateX(400px);
    }
    100% {
      opacity: 0;
      transform: translateX(550px);
    }
  }
  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateX(0);
    }
    33% {
      opacity: 1;
      transform: translateX(200px);
    }
    66% {
      opacity: 1;
      transform: translateX(400px);
    }
    100% {
      opacity: 0;
      transform: translateX(550px);
    }
  }

  .text {
    font-size: 3rem;
    font-family: Work Sans, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`;

export const PreviewContainer = styled.div`
  width: 600px;
  background: #fafafa;
  padding: 12px;

  h5 {
    margin: 20px 0;
  }
  .company-panel,
  .person-panel {
    margin-top: 0;
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  background: inherit;
  padding: 10px 0;
`;

export const AttachmentListContainer = styled.div`
  .attachment-btn-download {
    margin: 0 5px 0 auto;
    background: #009966;
    &:hover {
      border-color: #009966;
      background: rgba(0, 174, 111, 0.4);
    }
  }

  button {
    font-size: 0.8em;
    color: white;

    & + button {
      background: #ff3333;
      &:hover {
        background: rgba(243, 39, 53, 0.4);
        border-color: #ff3333;
      }
    }

    &:hover {
      color: #333;
    }
  }

  .MuiListItem-container:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const FoundResults = styled.div`
  margin: 24px 0;
`;

export const BoothRequestContainer = styled.div`
  width: 600px;
  background: #fafafa;
  padding: 12px;

  h5 {
    margin: 20px 0;
  }

  .company-panel {
    margin: 0;
  }

  .button-group button {
    margin: 2px;
    display: inline;
    padding: 5px;
    border: 1px solid #e6e1e1;
    font-weight: normal;
  }

  .item {
    border-bottom: none;
`;

export const AssociationContainer = styled.div`
  display: flex;

  &:hover > .delete-button {
    display: block;
  }

  .delete-button {
    display: none;
    margin: 5px 0;
  }

  .delete-button > button {
    height: 100%;
  }
`;

export const InputGroup = styled(DetailFormGroup)`
  width: 100%;
  > span {
    width: 100%;
  }
`;
