import styled from 'styled-components';

export const CouponHeading = styled.span`
  padding-bottom: 2px;
  font-size: 16px;
  padding-right: 25%;
`;

export const SpanCo = styled.span`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const EmailSpan = styled.span`
  text-transform: none;
`;

export const Title = styled.span`
  text-transform: capitalize;
`;

export const AlertTitle = styled.span`
  text-transform: capitalize;
  display: flow-root;
  align-items: center;
  svg {
    margin-right: 12px !important;
  }
  width: 90%;
`;

export const AlertDescription = styled.span`
  font-style: italic;
  font-size: 12px;
  display: block;
  width: 80%;
`;

export const AlertContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 8px;
`;

export const IncentiveCreatedSpan = styled.span`
  font-size: 12px;
`;

const typeCardWordsColor = (dataFromProps) => {
  const { selected, type } = dataFromProps;
  if (!selected) {
    return `#666`;
  }
  if (type === 'Buyer') {
    return `#173630`;
  }
  if (type === 'Exhibitor') {
    return `#595130`;
  }
  return 'intial';
};

export const CardCompanyName = styled.span`
  color: ${(props) => typeCardWordsColor(props)};
  font-weight: ${({ selected }) => (selected ? `bold` : `normal`)};
  p {
    font-size: smaller;
    font-weight: normal;
  }
`;

export const SpanCoId = styled.span`
  float: right;
  font-size: 12px;
  color: #666;
  display: inline;
  width: 100%;
  text-align: right;
`;

export const BodySpan = styled.span`
  width: 100%;
  a {
    text-transform: none;
    color: #0099cc;
    &:hover {
      color: #002946;
    }
  }
`;

export const AboutHeading = styled.span`
  font-weight: bold;
  font-size: 12px;
`;

export const AboutBody = styled.span`
  display: block;
  text-transform: capitalize;
`;

export const DetailEmailSpan = styled.span`
  text-transform: lowercase !important;
  a {
    color: #0099cc;
  }
`;

export const SendLabel = styled.span`
  width: 50%;
`;

export const Strong = styled.span`
  font-weight: bold;
  text-transform: capitalize;
`;

export const GroupId = styled.span`
  display: inline-block;
  background: #eee;
  padding: 4px 8px;
  margin-bottom: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 12px;
  float: right;
`;

export const ImportanceLabel = styled.span`
  color: ${(props) => (props.value === 'High' ? '#a50000' : 'black')}};
  margin-right: 5px;
`;
