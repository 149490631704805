import {
  Card,
  Badge,
  Paper,
  ListItem,
  FormControlLabel,
  AccordionDetails,
  ToggleButtonGroup,
} from '@mui/material';
import styled from 'styled-components';

export const AccountCard = styled(Card)`
  grid-column: 2 / 2;
  position: relative;
`;

export const PersonalizeCard = styled(Card)`
  grid-column: 1 / 2;
`;

export const UserCard = styled(Card)`
  width: 400px;
  grid-column: 1 / 2;
`;

export const CustomCard = styled(Card)`
  position: relative;
  margin-bottom: 20px;
  width: 500px !important;
  margin-right: 8px;
`;

export const CustomBadge = styled(Badge)`
  .MuiBadge-colorPrimary-3 {
    background-color: #ff6699;
  }
`;

export const GroupDetailsContainer = styled(Paper)`
  width: calc(100vw - 384px);
  margin: 0 0 0 auto;
  padding: 16px;

  .k-content {
    &.k-state-active {
      padding: 0;
      border-color: transparent;
    }
  }
  .k-tabstrip-items {
    .k-link {
      color: #0099cc;
    }
  }

  tr {
    &:hover {
      td:nth-child(2) .button--preview {
        display: block;
      }
    }
  }
`;

export const GroupMenuContainer = styled(Paper)`
  width: 280px;
  padding: 16px;
  position: relative;
`;

export const GroupListItem = styled(ListItem)`
  align-items: left !important;
  flex-direction: column;
  &.inActive {
    background: #eff0f1;
    color: #c5c7ca;
  }
  &.selected {
    background: #0099cc25;
  }
`;

export const FormControlCustom = styled(FormControlLabel)`
  position: absolute !important;
  top: 0;
  right: 10px;
`;

export const GroupFormControlLabel = styled(FormControlLabel)`
  align-items: right !important;
  right: 10px;
  position: absolute;
  margin-top: 8px;
`;

export const PanelDetails = styled(AccordionDetails)`
  flex-flow: column;

  .MuiTypography-overline-61 {
    color: #0099cc;
  }

  .MuiTypography-gutterBottom-68 {
    margin-bottom: 14px;
  }
`;

export const DefaultToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButton-root {
    height: 34px;
    text-transform: none;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .MuiToggleButton-root.Mui-selected {
    background-color: #0098ff;
    color: white;
  }
  .MuiToggleButton-root.Mui-selected:hover {
    background-color: #0098ff;
    opacity: 0.5;
    color: white;
  }
`;

export const GroupToggleButtonGroup = styled(DefaultToggleButtonGroup)`
  position: absolute;
  right: 0;
  margin: 12px 16px 0px 0px;
  .MuiToggleButtonGroup-groupedSizeSmall {
    padding: 4px 7px 4px 8px;
  }
`;

export const ContactsToggleButtonGroup = styled(DefaultToggleButtonGroup)`
  .MuiToggleButton-root {
    height: 26px;
  }
  position: absolute;
  right: 8px;
`;

export const ContractorsToggleButtonGroup = styled(ContactsToggleButtonGroup)`
  right: 16px;
  margin-top: 16px;
`;
